import {
  Article,
  Block,
  Box,
  ChevronRightIcon,
  Heading,
  Link,
  Spinner,
  Text,
} from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';

import { useContentRendering } from '../../context/ContentRenderingProvider';
import { useLatestNewsQuery } from '../../generated/content/graphql';
import { format, parse } from '../../helpers/date-fns';
import { timeZone } from '../../helpers/date-fns/timezone';
import { groupBy } from '../../helpers/groupBy';
import { useContentRenderingUrls } from '../../hooks/useContentRenderingUrls';

interface LatestNewsProps {
  readMoreUrl?: string;
}

const LatestNews: React.FC<React.PropsWithChildren<LatestNewsProps>> = ({ readMoreUrl }) => {
  const { applicationKey, client, dateFormatFn } = useContentRendering();
  const urls = useContentRenderingUrls();

  const { loading: isLoading, data } = useLatestNewsQuery({
    variables: { applicationKey },
    client,
  });

  if (isLoading) {
    return <Spinner size={48} />;
  }

  if (!data?.news?.length) {
    return (
      <Block spaceAfter={8}>
        <Heading heading="h2" renderAs="h2" spaceAfter={2}>
          <Trans>Nieuws van vandaag</Trans>
        </Heading>
        <Text type="paragraph">
          <Trans>Geen nieuws gevonden</Trans>
        </Text>
      </Block>
    );
  }

  const news = [...data.news].sort((a, b) =>
    b.publicationDate && a.publicationDate
      ? parse(b.publicationDate).getTime() - parse(a.publicationDate).getTime()
      : -1,
  );

  // TODO: Check if we can use the date formatting from the ContentRenderingProvider.
  // but because we want a specifc english(non localized) format for grouping I dont think thats possible.
  const today = format(new Date(), 'yyyy-MM-dd');
  const itemsByDate = groupBy(news, (item) => item.publicationDate || '');
  const newsItems = Object.keys(itemsByDate).map((key) => ({
    key,
    // TODO: add check formatting
    title: dateFormatFn(parse(key), { dateStyle: 'long', timeZone }),
    items: itemsByDate[key],
  }));

  return (
    <Block spaceAfter={8} testId="news">
      {newsItems.map((item) => (
        <Box key={item.key} data-test-id="newsItem" spaceAfter={10}>
          {today === item.key ? (
            <Heading heading="h2" renderAs="h2" spaceAfter={6} testId="newsTitleToday">
              <Trans>Nieuws van vandaag</Trans>
            </Heading>
          ) : (
            <Heading heading="h3" renderAs="h2" spaceAfter={6} testId="newsTitle">
              {item.title}
            </Heading>
          )}
          {item.items?.map(({ id, title, teaser, publicationDate }, index) => (
            <Article
              key={id}
              link={`${urls.contentUrl}/${id}`}
              metadata={
                publicationDate ? (
                  <Trans>
                    Gepubliceerd op{' '}
                    {dateFormatFn(parse(publicationDate), { dateStyle: 'short', timeZone })}
                  </Trans>
                ) : null
              }
              spaceAfter={6}
              teaser={teaser}
              testId={`newsItem_${index}`}
              title={title}
            />
          ))}
        </Box>
      ))}
      {readMoreUrl && (
        <Link prefixAdornment={<ChevronRightIcon />} testId="newsLink" to={readMoreUrl}>
          <Trans>Bekijk ouder nieuws</Trans>
        </Link>
      )}
    </Block>
  );
};

export { LatestNews };
