import {
  Block,
  ChevronRightIcon,
  Clickable,
  CloseIcon,
  Link,
  spacing,
  StyledHTML,
  Text,
} from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useAuthentication } from '@repo/auth';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  MarketingBlockDocument,
  type MarketingBlockQuery,
  type MarketingBlockQueryVariables,
} from '../../generated/cms/graphql';
import { getCookie, setCookie } from '../../helpers/cookies';
import { useCMSQuery } from '../../hooks/useCmsQuery';

const MarketingContainer = styled(Block).withConfig({
  componentId: 'marketingContainer',
} as any)`
  border: 1px solid #dddcdc;
  margin-bottom: ${spacing(6)};
`;

const MarketingHeader = styled.div`
  margin-bottom: ${spacing(2)};
  display: flex;
  justify-content: space-between;
`;

const MarketingClose = styled(Clickable)`
  font-size: 12px;
  padding: ${spacing(1)};
`;

const MarketingBlock = () => {
  useLingui();
  const { response, isLoading } = useCMSQuery<MarketingBlockQuery, MarketingBlockQueryVariables>(
    MarketingBlockDocument,
  );

  const data = response?.siteSettings?.MarketingBlock;

  const { isLoggedIn, isInitialized } = useAuthentication();
  const [isShown, setShown] = useState(false);

  const closeMessage = useCallback(() => {
    setShown(false);
    setCookie('marketingMessage', '1');
  }, []);

  useEffect(() => {
    if (!isInitialized || isLoading || !data?.text || !data.title) return;

    const { shouldShowLoggedIn } = data;
    const shouldShowMessage =
      (shouldShowLoggedIn && isLoggedIn) || (!shouldShowLoggedIn && !isLoggedIn);

    setShown(shouldShowMessage && getCookie('marketingMessage') !== '1');
  }, [isLoggedIn, isInitialized, isLoading, data]);

  if (isLoading || !data || (!data.text && !data.title) || (data.title && !data.text)) {
    return null;
  }

  const { title, text, link } = data;

  return (
    <AnimatePresence key="marketingBlock">
      {isShown && (
        <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
          <MarketingContainer px={6} py={6} testId="marketingBlock">
            <MarketingHeader>
              {title && <Text type="labelBold">{title}</Text>}
              <MarketingClose aria-label={t`Sluiten`} type="button" onClick={closeMessage}>
                <CloseIcon />
              </MarketingClose>
            </MarketingHeader>
            {text && <StyledHTML content={text} spaceAfter={4} />}
            {link?.href && link.title && (
              <Link suffixAdornment={<ChevronRightIcon />} to={link.href}>
                {link.title}
              </Link>
            )}
          </MarketingContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { MarketingBlock };
