import nl from 'date-fns/locale/nl';
import { formatInTimeZone } from 'date-fns-tz';

import { timeZone } from './timezone';

const locales = { nl };

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
function format(date: Date | string | number, formatStr = 'PP') {
  return formatInTimeZone(date, timeZone, formatStr, {
    locale: locales.nl, // or global.__localeId__
  });
}
export default format;
