import { Flex, spacing, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { useSiteConfig } from '../../context';

const StyledFooterContainer = styled(Flex)`
  margin-top: ${spacing(10)};
  padding-top: ${spacing(6)};
  border-top: 1px solid ${({ theme }) => theme.designTokens.colors.grey20};
`;

const LevebreSarrutLogo = () => {
  return (
    <svg fill="none" height="46" viewBox="0 0 86 46" width="86" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 11.5C0.305325 11.5 0.610651 11.5 0.915976 11.5C1.06864 11.5 1.06864 11.5523 1.06864 11.6568C1.06864 13.6432 1.06864 15.5773 1.06864 17.5636C1.06864 18.7136 1.06864 19.8636 1.06864 21.0136C1.06864 21.0659 1.06864 21.1182 1.06864 21.1705C1.11953 21.6409 1.32308 21.85 1.78106 21.9023C1.93373 21.9023 2.0355 21.9023 2.18817 21.9023C3.61302 21.9023 5.08876 21.9023 6.51361 21.9023C6.66627 21.9023 6.71716 21.9545 6.71716 22.1114C6.71716 22.3205 6.71716 22.4773 6.71716 22.6864C6.71716 22.8955 6.61538 23 6.41183 23C4.88521 23 3.35858 23 1.83195 23C1.6284 23 1.42485 22.9477 1.27219 22.8955C0.610651 22.7386 0.20355 22.3205 0.101775 21.6409C0.101775 21.5886 0.101775 21.4841 0.0508876 21.4318C0 18.0864 0 14.7932 0 11.5Z"
        fill="#273481"
      />
      <path
        d="M55.4166 0C54.8568 1.62045 54.2971 3.24091 53.7373 4.86136C53.1267 6.63864 52.516 8.41591 51.9054 10.2455C51.8545 10.4023 51.8036 10.4545 51.6509 10.4545C51.2947 10.4545 50.9385 10.4545 50.5823 10.4545C50.7349 9.98409 50.9385 9.46136 51.0912 8.99091C52.0071 6.325 52.9231 3.60682 53.8391 0.940909C53.9917 0.470455 54.2971 0.209091 54.7551 0.0522727C54.7551 0.0522727 54.8059 0.0522727 54.8059 0C55.0095 0 55.213 0 55.4166 0Z"
        fill="#E3042E"
      />
      <path
        d="M39.8958 16.9886C40.5574 17.4068 40.9645 17.9295 41.168 18.6614C41.3716 19.55 41.3207 20.4386 41.0154 21.3273C40.6591 22.2682 39.9976 22.7386 39.0307 22.8955C38.7254 22.9477 38.471 23 38.1657 23C36.8426 23 35.5704 23 34.2473 23C34.0947 23 34.0438 23 34.0438 22.7909C34.0438 19.0795 34.0438 15.3682 34.0438 11.7091C34.0438 11.5523 34.0947 11.5 34.2473 11.5C35.6213 11.5 36.9952 11.5 38.3692 11.5C39.1325 11.5 39.8449 11.7091 40.4556 12.2841C40.8118 12.65 41.0154 13.1205 41.1171 13.5909C41.2698 14.2182 41.2698 14.8455 41.0662 15.4727C40.8627 16.1523 40.5065 16.675 39.8958 16.9886ZM35.1124 19.7068C35.1124 20.3864 35.1124 21.0659 35.1124 21.6932C35.1124 21.85 35.1633 21.9023 35.2651 21.85C35.6213 21.85 35.9775 21.85 36.3337 21.85C37.0461 21.85 37.8094 21.9023 38.5219 21.85C39.3361 21.7977 39.8449 21.3273 40.0485 20.5432C40.1503 20.125 40.1503 19.7068 40.0994 19.2886C39.9976 18.2432 39.2343 17.5114 38.2674 17.5114C37.2497 17.5114 36.2319 17.5114 35.2142 17.5114C35.1124 17.5114 35.0615 17.5636 35.0615 17.6682C35.1124 18.3477 35.1124 19.0273 35.1124 19.7068ZM35.1124 14.4795C35.1124 15.0545 35.1124 15.6818 35.1124 16.2568C35.1124 16.3614 35.1124 16.4136 35.2142 16.4136C36.2828 16.4136 37.4023 16.4136 38.471 16.4136C39.2852 16.4136 39.8958 15.8909 40.0485 15.1068C40.1503 14.7409 40.1503 14.3227 40.0485 13.9568C39.9467 13.2773 39.5396 12.9114 38.929 12.7023C38.7254 12.65 38.5219 12.5977 38.3183 12.5977C37.3006 12.5977 36.2828 12.5977 35.2651 12.5977C35.1124 12.5977 35.1124 12.65 35.1124 12.7545C35.1124 13.3295 35.1124 13.9045 35.1124 14.4795Z"
        fill="#283581"
      />
      <path
        d="M53.0248 17.1978C53.0248 15.3682 53.0248 13.4864 53.0248 11.6568C53.0248 11.5 53.0757 11.4478 53.2284 11.4478C54.7041 11.4478 56.1799 11.4478 57.6556 11.4478C59.0296 11.4478 59.8947 12.1273 60.2 13.5387C60.4544 14.6364 60.4035 15.6818 60.0473 16.7796C59.7929 17.5114 59.284 17.9818 58.5207 18.1387C58.3172 18.1909 58.3172 18.1909 58.4189 18.4C59.1314 19.8637 59.8438 21.3273 60.6071 22.7909C60.7089 22.9478 60.7089 22.9478 60.5053 22.9478C60.2509 22.9478 59.9964 22.9478 59.742 22.9478C59.5893 22.9478 59.4876 22.8955 59.4367 22.7387C58.9278 21.6932 58.368 20.5955 57.8592 19.55C57.6556 19.1318 57.4521 18.7659 57.2485 18.3478C57.1976 18.2432 57.1467 18.1909 57.045 18.1909C56.129 18.1387 55.213 18.0864 54.297 18.0341C54.1444 18.0341 54.1444 18.0864 54.1444 18.1909C54.1444 19.7068 54.1444 21.2228 54.1444 22.7387C54.1444 22.8955 54.0935 22.9478 53.9408 22.9478C53.7373 22.9478 53.4828 22.9478 53.2793 22.9478C53.1266 22.9478 53.0757 22.8955 53.0757 22.7387C53.0248 20.9091 53.0248 19.0796 53.0248 17.1978ZM54.0935 14.8455C54.0935 15.525 54.0935 16.2568 54.0935 16.9364C54.0935 17.0409 54.0935 17.0932 54.1953 17.0932C55.4166 17.0932 56.6379 17.0932 57.8083 17.0932C58.5207 17.0932 58.8769 16.7796 59.0296 16.1523C59.2331 15.3159 59.2331 14.4273 59.0296 13.5909C58.8769 13.0682 58.5716 12.7546 58.0118 12.65C57.8592 12.5978 57.7065 12.5978 57.5538 12.5978C56.4343 12.5978 55.3657 12.5978 54.2461 12.5978C54.0935 12.5978 54.0935 12.65 54.0935 12.7546C54.0935 13.4864 54.0935 14.1659 54.0935 14.8455Z"
        fill="#293682"
      />
      <path
        d="M65.7976 35.4409C66.0521 35.9113 66.2556 36.3818 66.4592 36.8522C66.968 37.95 67.5278 38.9954 68.0367 40.0932C68.0876 40.1977 68.0876 40.25 67.9349 40.25C67.6296 40.25 67.3751 40.25 67.0698 40.25C66.968 40.25 66.9171 40.1977 66.8662 40.0932C66.1538 38.6295 65.4414 37.1659 64.729 35.7022C64.6272 35.5454 64.5763 35.4932 64.3728 35.4932C63.5077 35.4409 62.6426 35.3886 61.7775 35.3363C61.5739 35.3363 61.5739 35.3886 61.5739 35.5454C61.5739 37.0613 61.5739 38.525 61.5739 40.0409C61.5739 40.1977 61.5231 40.25 61.3704 40.25C61.116 40.25 60.8615 40.25 60.6071 40.25C60.5053 40.25 60.5053 40.1977 60.5053 40.1454C60.5053 40.0932 60.5053 40.0932 60.5053 40.0409C60.5053 36.3818 60.5053 32.6704 60.5053 29.0113C60.5053 28.8022 60.5562 28.8022 60.7089 28.8022C62.1846 28.8022 63.6603 28.8022 65.0852 28.8022C66.4592 28.8022 67.3242 29.4818 67.6296 30.8932C67.884 31.9909 67.8331 33.1409 67.426 34.2386C67.1716 34.9182 66.6627 35.3363 66.0012 35.4932C65.9503 35.3886 65.8485 35.4409 65.7976 35.4409ZM61.5739 32.0954C61.5739 32.775 61.5739 33.4545 61.5739 34.1341C61.5739 34.2909 61.6248 34.3432 61.7775 34.3432C62.897 34.3432 63.9657 34.3432 65.0852 34.3432C65.2379 34.3432 65.3905 34.3432 65.5432 34.2909C66.0521 34.2386 66.4083 33.925 66.5609 33.4022C66.7645 32.6704 66.7645 31.9386 66.6627 31.2068C66.6118 30.8932 66.51 30.5795 66.3065 30.3704C66.0012 30.0045 65.5941 29.9 65.1361 29.9C64.0166 29.9 62.897 29.9 61.7266 29.9C61.5739 29.9 61.5739 29.9522 61.5739 30.0568C61.5739 30.6841 61.5739 31.3636 61.5739 32.0954Z"
        fill="#293682"
      />
      <path
        d="M56.2308 35.4409C56.5361 36.1205 56.8923 36.7478 57.1977 37.4273C57.6048 38.3159 58.0627 39.1523 58.4698 40.0409C58.5207 40.1455 58.5207 40.1978 58.3681 40.1978C58.0627 40.1978 57.8083 40.1978 57.503 40.1978C57.4012 40.1978 57.3503 40.1455 57.2994 40.0409C56.587 38.5773 55.8746 37.1137 55.1621 35.65C55.0604 35.4932 54.9586 35.3887 54.8059 35.3887C53.9408 35.3364 53.0758 35.2841 52.2107 35.2318C52.058 35.2318 52.0071 35.2318 52.0071 35.3887C52.0071 36.9046 52.0071 38.4205 52.0071 39.9364C52.0071 40.0932 51.9562 40.1455 51.8036 40.1455C51.5491 40.1455 51.3456 40.1455 51.0911 40.1455C50.9385 40.1455 50.9385 40.0932 50.9385 39.9887C50.9385 38.3682 50.9385 36.7478 50.9385 35.1273C50.9385 33.0364 50.9385 30.9978 50.9385 28.9068C50.9385 28.75 50.9894 28.6978 51.142 28.6978C52.6687 28.6978 54.1444 28.6978 55.671 28.6978C56.9941 28.6978 57.7574 29.3773 58.0627 30.6841C58.3172 31.7818 58.2663 32.9318 57.9101 34.0296C57.6556 34.7614 57.1468 35.1796 56.3835 35.3887C56.3835 35.3887 56.3326 35.4409 56.2308 35.4409C56.2817 35.4409 56.2308 35.4409 56.2308 35.4409ZM52.0071 32.0432C52.0071 32.7228 52.0071 33.4546 52.0071 34.1341C52.0071 34.2909 52.058 34.3432 52.2107 34.3432C53.3302 34.3432 54.3988 34.3432 55.5184 34.3432C55.671 34.3432 55.8746 34.3432 56.0272 34.2909C56.5361 34.2387 56.8414 33.9773 56.9941 33.4546C57.2485 32.5659 57.2485 31.6773 56.9941 30.8409C56.8923 30.3705 56.587 30.0568 56.129 29.9523C55.9764 29.9 55.7728 29.8478 55.6201 29.8478C54.5006 29.8478 53.3302 29.8478 52.2107 29.8478C52.058 29.8478 52.058 29.9 52.058 30.0046C52.058 30.6841 52.0071 31.3637 52.0071 32.0432Z"
        fill="#273481"
      />
      <path
        d="M40.4047 40.1978C40.71 39.2568 41.0154 38.3682 41.3207 37.4273C42.2876 34.5523 43.3053 31.7296 44.2722 28.8546C44.3231 28.75 44.374 28.6978 44.4757 28.6978C44.8319 28.6978 45.1882 28.6978 45.4935 28.6978C45.5953 28.6978 45.6461 28.75 45.697 28.8546C46.8166 32.0955 47.9361 35.3364 49.0556 38.6296C49.2083 39.1 49.3609 39.5705 49.5645 40.0932C49.6154 40.25 49.5645 40.25 49.4627 40.25C49.1574 40.25 48.8012 40.25 48.4959 40.25C48.3941 40.25 48.3432 40.1978 48.2923 40.0932C47.9361 39.0478 47.5799 38.0023 47.2237 37.0091C47.1728 36.9046 47.1219 36.8523 46.9692 36.8523C45.6461 36.8523 44.2722 36.8523 42.9491 36.8523C42.8473 36.8523 42.7456 36.9046 42.7456 37.0091C42.3893 38.0546 42.0331 39.0478 41.7278 40.0932C41.6769 40.25 41.626 40.3023 41.4734 40.3023C41.1171 40.1978 40.7609 40.1978 40.4047 40.1978ZM43.2035 35.7023C43.2544 35.7023 43.2544 35.7023 43.2544 35.7023C44.374 35.7023 45.4935 35.7023 46.6639 35.7023C46.8166 35.7023 46.7657 35.65 46.7657 35.5455C46.4095 34.5 46.0532 33.4023 45.697 32.3568C45.4935 31.7296 45.2899 31.1023 45.0355 30.4228C44.374 32.2 43.8142 33.925 43.2035 35.7023Z"
        fill="#283581"
      />
      <path
        d="M70.7337 32.9318C70.7337 34.0818 70.7337 35.284 70.7337 36.434C70.7337 36.8 70.7337 37.1659 70.8355 37.5318C71.0899 38.4204 71.6497 38.9431 72.5148 39.1C73.1763 39.2568 73.8379 39.2045 74.4994 39.1C75.4154 38.9431 76.026 38.159 76.1787 37.2181C76.2805 36.5909 76.2296 35.9636 76.2296 35.3886C76.2296 33.2454 76.2296 31.05 76.2296 28.9068C76.2296 28.75 76.2805 28.6977 76.4331 28.6977C76.6876 28.6977 76.942 28.6977 77.1964 28.6977C77.2982 28.6977 77.2982 28.75 77.2982 28.8022C77.2982 28.8545 77.2982 28.8545 77.2982 28.9068C77.2982 31.2068 77.2982 33.559 77.2982 35.859C77.2982 36.5909 77.2473 37.2704 77.0947 37.95C76.7893 39.0477 76.1278 39.7795 75.0592 40.0409C74.0414 40.3022 72.9728 40.3022 71.9041 40.0409C70.6828 39.7272 70.0213 38.8909 69.7669 37.6363C69.6651 37.009 69.6142 36.3818 69.6142 35.7545C69.6142 33.4545 69.6142 31.1545 69.6142 28.8022C69.6142 28.6454 69.6651 28.5931 69.8177 28.5931C70.0722 28.5931 70.2757 28.5931 70.5302 28.5931C70.6828 28.5931 70.6828 28.6454 70.6828 28.75C70.6828 29.9522 70.6828 31.2068 70.6828 32.409C70.7337 32.6704 70.7337 32.775 70.7337 32.9318Z"
        fill="#283581"
      />
      <path
        d="M36.9444 29.7955C36.181 29.7955 35.4686 29.6909 34.7562 29.7432C34.5526 29.7432 34.4 29.7955 34.2473 29.8478C33.7893 30.0046 33.5349 30.3182 33.4331 30.7887C33.3313 31.5205 33.3313 32.3046 33.4331 33.0364C33.484 33.6114 33.8911 33.925 34.5526 33.9773C35.3668 34.0296 36.1302 33.9773 36.9444 34.0296C37.3006 34.0296 37.6568 34.0296 38.013 34.1341C38.8781 34.3432 39.2852 34.9705 39.4887 35.8068C39.5396 36.0682 39.5905 36.3296 39.5905 36.5909C39.5905 37.1137 39.6414 37.6364 39.5396 38.1591C39.387 39.3091 38.8781 39.8841 37.7586 40.1978C37.1479 40.3546 36.4864 40.3546 35.8757 40.3023C34.858 40.25 33.8911 40.1455 32.9242 39.9887C32.7716 39.9887 32.7207 39.9364 32.7207 39.7273C32.7207 39.5705 32.7207 39.4137 32.7207 39.2568C32.7207 39.1 32.7716 39.0478 32.9242 39.0478C33.942 39.1523 34.9597 39.2046 35.9775 39.2046C36.5373 39.2046 37.0461 39.2568 37.6059 39.1C38.2165 38.8909 38.5728 38.4728 38.5728 37.7932C38.5728 37.2705 38.6236 36.8 38.5728 36.2773C38.471 35.4932 38.1148 35.1796 37.3515 35.1796C36.5881 35.1796 35.8248 35.1796 35.0615 35.1796C34.6035 35.1796 34.1964 35.1273 33.7893 34.9705C33.1278 34.7091 32.7207 34.2909 32.6189 33.6114C32.4154 32.5659 32.4154 31.5205 32.6189 30.475C32.8225 29.4818 33.5349 28.9591 34.4509 28.8023C35.1124 28.6455 35.7231 28.6978 36.3846 28.75C37.3006 28.8023 38.2165 28.9068 39.0816 29.0114C39.2343 29.0114 39.2852 29.0637 39.2852 29.2205C39.2852 29.3773 39.2852 29.5864 39.2852 29.7432C39.2852 29.9 39.2343 29.9523 39.0816 29.9C38.2165 29.8478 37.5041 29.7955 36.9444 29.7955Z"
        fill="#283581"
      />
      <path
        d="M46.8674 21.275C47.071 20.5955 47.3254 20.0205 47.529 19.3932C48.3941 16.8318 49.31 14.2182 50.1751 11.6568C50.226 11.5523 50.2769 11.5 50.3787 11.5C50.684 11.5 50.9893 11.5 51.2947 11.5C51.4473 11.5 51.4982 11.5523 51.4473 11.7091C51.0911 12.8068 50.684 13.8523 50.3278 14.95C49.4118 17.5636 48.5467 20.1773 47.6308 22.7909C47.5799 22.9477 47.4781 23 47.3254 23C47.0201 23 46.6639 23 46.3586 23C46.2568 23 46.2059 22.9477 46.155 22.8432C44.8828 19.1318 43.6106 15.3682 42.3384 11.6568C42.2876 11.5 42.3384 11.5 42.4402 11.5C42.7455 11.5 43.0509 11.5 43.3562 11.5C43.458 11.5 43.5089 11.5523 43.5597 11.6568C44.4248 14.1659 45.2899 16.675 46.1041 19.1318C46.4095 19.8114 46.613 20.5432 46.8674 21.275Z"
        fill="#283581"
      />
      <path
        d="M7.98938 17.3023C7.98938 16.1 7.98938 14.8455 7.98938 13.6432C7.98938 13.3818 7.98938 13.1205 8.09115 12.8591C8.29471 12.0227 8.95624 11.5 9.82133 11.5C10.8391 11.5 11.8568 11.6045 12.8237 11.6045C13.4344 11.6568 13.9941 11.6568 14.6048 11.7091C14.7065 11.7091 14.7574 11.7614 14.7574 11.8659C14.7574 12.075 14.7574 12.2318 14.7574 12.4409C14.7574 12.5977 14.7065 12.5977 14.6048 12.5977C13.0781 12.5977 11.6024 12.5977 10.0758 12.5977C10.0249 12.5977 9.974 12.5977 9.92311 12.5977C9.41423 12.65 9.21068 12.8591 9.15979 13.3818C9.15979 13.5386 9.15979 13.6955 9.15979 13.8C9.15979 14.7409 9.15979 15.6295 9.15979 16.5705C9.15979 16.675 9.15979 16.7273 9.31246 16.7273C10.432 16.7273 11.5515 16.7795 12.7219 16.7795C13.1799 16.7795 13.6379 16.8318 14.1468 16.8318C14.2486 16.8318 14.2994 16.8318 14.2994 16.9886C14.2994 17.1455 14.2994 17.3023 14.2994 17.4591C14.2994 17.6682 14.1977 17.7727 13.9941 17.7727C12.8237 17.825 11.6533 17.825 10.4829 17.8773C10.1267 17.8773 9.71956 17.8773 9.36334 17.9295C9.31246 17.9295 9.21068 17.9295 9.26157 18.0341C9.26157 19.0795 9.21068 20.1773 9.26157 21.2227C9.31246 21.7455 9.46512 21.9545 10.0249 22.0068C10.1775 22.0068 10.3302 22.0068 10.4829 22.0068C11.9077 22.0068 13.3326 22.0068 14.7574 22.0068C14.9101 22.0068 14.961 22.0591 14.961 22.2159C14.961 22.3727 14.961 22.5295 14.961 22.6864C14.961 22.8432 14.9101 22.8432 14.8083 22.8432C13.9432 22.8955 13.129 22.9477 12.2639 22.9477C11.5515 23 10.8391 23 10.0758 23.0523C9.82133 23.0523 9.56689 23 9.31246 22.9477C8.60003 22.7386 8.29471 22.2159 8.24382 21.4841C8.19293 21.2227 8.19293 21.0136 8.19293 20.7523C7.98938 19.55 7.98938 18.4523 7.98938 17.3023Z"
        fill="#283581"
      />
      <path
        d="M62.3373 17.3023C62.3373 16.0477 62.3373 14.8455 62.3373 13.5909C62.3373 13.3295 62.3373 13.0682 62.4391 12.8068C62.6426 12.0227 63.3041 11.4477 64.1692 11.5C64.9834 11.5 65.7467 11.5523 66.5609 11.6045C67.3751 11.6568 68.1893 11.6568 68.9527 11.7091C69.0544 11.7091 69.1053 11.7614 69.1053 11.8659C69.1053 12.0227 69.1053 12.2318 69.1053 12.4409C69.1053 12.5977 69.0544 12.65 68.9018 12.65C67.3751 12.65 65.8485 12.65 64.3219 12.65C64.2201 12.65 64.1183 12.65 64.0166 12.65C63.6604 12.7023 63.4568 12.8591 63.4059 13.225C63.355 13.3818 63.355 13.4864 63.355 13.6432C63.355 14.6364 63.355 15.5773 63.355 16.5705C63.355 16.675 63.355 16.7273 63.5077 16.7795C64.6272 16.7795 65.7467 16.8318 66.9172 16.8318C67.3751 16.8318 67.8331 16.8841 68.2911 16.8841C68.3929 16.8841 68.4438 16.9364 68.4438 17.0409C68.4438 17.1977 68.4438 17.3545 68.4438 17.5114C68.4438 17.7205 68.342 17.825 68.1385 17.825C66.968 17.8773 65.7976 17.8773 64.6781 17.9295C64.271 17.9295 63.9148 17.9295 63.5077 17.9818C63.4059 17.9818 63.4059 17.9818 63.4059 18.0864C63.4059 19.1318 63.4059 20.1773 63.4059 21.275C63.4059 21.7977 63.6095 22.0068 64.1692 22.0591C64.3219 22.0591 64.4746 22.0591 64.6272 22.0591C66.0521 22.0591 67.4769 22.0591 68.9018 22.0591C69.0544 22.0591 69.1053 22.1114 69.1053 22.2682C69.1053 22.425 69.1053 22.6341 69.1053 22.7909C69.1053 22.9477 69.0544 22.9477 68.9527 22.9477C68.342 22.9477 67.7822 23 67.1716 23.0523C66.2047 23.1045 65.2379 23.1568 64.2201 23.2091C63.9657 23.2091 63.7112 23.1568 63.4568 23.1045C62.7953 22.8955 62.4899 22.425 62.3882 21.7455C62.3373 21.5364 62.3373 21.3273 62.3373 21.1182C62.3373 19.7068 62.3373 18.5045 62.3373 17.3023Z"
        fill="#283581"
      />
      <path
        d="M25.1384 17.3023C25.1384 16.0477 25.1384 14.7932 25.1384 13.5386C25.1384 13.2773 25.1893 13.0159 25.2402 12.7545C25.4438 12.0227 26.1562 11.5 26.9195 11.5C27.9372 11.5 28.9041 11.6045 29.9219 11.6045C30.5325 11.6568 31.0923 11.6568 31.7029 11.7091C31.8047 11.7091 31.8556 11.7614 31.8556 11.8659C31.8556 12.0227 31.8556 12.1795 31.8556 12.3364C31.8556 12.4932 31.8047 12.5455 31.652 12.5455C30.1254 12.5455 28.6497 12.5455 27.123 12.5455C27.0722 12.5455 26.9704 12.5455 26.9195 12.5455C26.4615 12.5977 26.258 12.8591 26.2071 13.3295C26.2071 13.3818 26.2071 13.4864 26.2071 13.5386C26.2071 14.4795 26.2071 15.4727 26.2071 16.4136C26.2071 16.5705 26.2071 16.6227 26.4106 16.6227C27.5301 16.6227 28.6497 16.675 29.7692 16.675C30.2272 16.675 30.6852 16.7273 31.1432 16.7273C31.2449 16.7273 31.2958 16.7795 31.2958 16.8841C31.2958 17.0409 31.2958 17.1977 31.2958 17.3545C31.2958 17.5636 31.1941 17.6682 30.9905 17.6682C29.871 17.7205 28.7006 17.7205 27.581 17.7727C27.1739 17.7727 26.7668 17.7727 26.3597 17.825C26.258 17.825 26.258 17.8773 26.258 17.9818C26.258 19.0273 26.258 20.0727 26.258 21.1182C26.258 21.6409 26.4615 21.85 26.9704 21.9023C27.123 21.9023 27.3266 21.9545 27.4793 21.9545C28.9041 21.9545 30.2781 21.9545 31.7029 21.9545C31.8556 21.9545 31.9065 22.0068 31.9065 22.1636C31.9065 22.3205 31.9065 22.5295 31.9065 22.6864C31.9065 22.7909 31.8556 22.8432 31.7538 22.8432C30.6343 22.8955 29.5656 22.9477 28.4461 23C27.9881 23 27.4793 23 27.0213 23.0523C26.8177 23.0523 26.5633 23 26.3597 22.9477C25.6982 22.7909 25.2911 22.3205 25.1893 21.6409C25.1384 21.4318 25.1384 21.275 25.1384 21.0659C25.1384 19.8114 25.1384 18.5568 25.1384 17.3023Z"
        fill="#283581"
      />
      <path
        d="M16.8947 18.0864C16.8947 16.6228 16.8947 15.1592 16.8947 13.6955C16.8947 13.3296 16.8947 13.016 16.9964 12.7023C17.2 11.9705 17.9124 11.4478 18.6757 11.5001C19.6935 11.5001 20.7112 11.6046 21.6781 11.6046C22.2887 11.6569 22.8485 11.6569 23.4591 11.7091C23.5609 11.7091 23.6118 11.7614 23.6118 11.866C23.6118 12.0751 23.6118 12.2319 23.6118 12.441C23.6118 12.5978 23.5609 12.5978 23.4591 12.5978C21.9325 12.5978 20.4568 12.5978 18.9302 12.5978C18.8793 12.5978 18.8284 12.5978 18.7775 12.5978C18.2686 12.5978 18.0142 12.8592 17.9633 13.3819C17.9124 13.9569 17.9633 14.5319 17.9633 15.1069C17.9633 15.5773 17.9633 16.0478 17.9633 16.5182C17.9633 16.6228 17.9633 16.6751 18.116 16.6751C19.2355 16.6751 20.355 16.7273 21.4745 16.7273C21.9325 16.7273 22.3905 16.7796 22.8994 16.7796C23.0012 16.7796 23.052 16.8319 23.052 16.9364C23.052 17.0932 23.052 17.2501 23.052 17.3546C23.052 17.5637 22.9503 17.6682 22.7467 17.6682C21.5763 17.7205 20.4059 17.7205 19.2864 17.7728C18.8793 17.7728 18.5231 17.8251 18.116 17.8251C17.9633 17.8251 18.0142 17.9296 18.0142 18.0341C18.0142 19.6023 18.0142 21.1705 18.0142 22.7387C18.0142 22.9478 17.9633 23.0001 17.7597 23.0001C17.5562 23.0001 17.3018 23.0001 17.0982 23.0001C16.9455 23.0001 16.8947 22.9478 16.8947 22.791C16.8947 21.2228 16.8947 19.6546 16.8947 18.0864Z"
        fill="#283581"
      />
      <path
        d="M81.6746 34.9705C81.6746 33.2978 81.6746 31.6251 81.6746 29.9523C81.6746 29.7955 81.6237 29.7432 81.471 29.7432C80.555 29.7432 79.6391 29.7432 78.7231 29.7432C78.5704 29.7432 78.5195 29.691 78.5704 29.5864C78.5704 29.3251 78.5704 29.0637 78.5704 28.8023C78.5704 28.6978 78.5704 28.6455 78.7231 28.6455C81.1148 28.6455 83.4556 28.6455 85.8473 28.6455C85.9491 28.6455 86 28.6455 86 28.8023C86 29.0114 86 29.2205 86 29.4296C86 29.691 85.8982 29.7955 85.6438 29.7955C84.7787 29.7955 83.8627 29.7955 82.9976 29.7955C82.845 29.7955 82.7941 29.8478 82.7941 30.0046C82.7941 33.3501 82.7941 36.6432 82.7941 39.9887C82.7941 40.1455 82.7432 40.1978 82.5905 40.1978C82.3361 40.1978 82.1325 40.1978 81.8781 40.1978C81.7763 40.1978 81.7255 40.1455 81.7255 40.041C81.7255 39.0478 81.7255 38.0546 81.7255 37.0614C81.6746 36.3819 81.6746 35.7023 81.6746 34.9705Z"
        fill="#283481"
      />
      <path
        d="M38.7254 45.9478C38.4201 45.9478 38.4201 45.9478 38.5219 45.6864C38.9799 44.2751 39.4887 42.8637 39.9467 41.5046C39.9976 41.3478 40.0485 41.3478 40.2012 41.3478C40.5065 41.3478 40.8118 41.3478 41.1171 41.3478C41.2189 41.3478 41.2698 41.3478 41.2189 41.5046C40.7609 42.7591 40.3538 44.0137 39.9467 45.2682C39.7432 45.6864 39.3361 45.9478 38.7254 45.9478Z"
        fill="#293682"
      />
    </svg>
  );
};

const Footer = () => {
  const { footerBrandName } = useSiteConfig();

  return (
    <StyledFooterContainer justifyContent="space-between" testId="footer">
      <Text color="grey70" data-onboarding="copyright" type="labelSmall">
        <Trans>
          Copyright © {new Date().getFullYear()} {footerBrandName || 'Sdu'}
        </Trans>
      </Text>
      <Flex flexDirection="column">
        <Text color="grey70" spaceAfter={1} type="labelSmall">
          <Trans>Onderdeel van</Trans>
        </Text>
        <LevebreSarrutLogo data-onboarding="ls-logo" />
      </Flex>
    </StyledFooterContainer>
  );
};

export { Footer };
