import { LatestNews } from '@elseu/sdu-titan-content-rendering';

import { useSiteConfig } from '../../context/SiteConfigProvider';

const News = () => {
  const { featureFlags, urls } = useSiteConfig();

  if (!featureFlags.WITH_NEWS) {
    return null;
  }

  return (
    <section>
      <LatestNews readMoreUrl={urls.newsOverviewUrl} />
    </section>
  );
};

export default News;
